<template>
  <div class="quiz-list">
    <v-card-title class="text-h4">
      <v-layout justify-space-between>
        {{ title }}
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :header-props="headerProps"
          :items="quiz.data"
          :search="tableSearch"
          :loading="quiz.status === 1"
          fixed-header
          item-key=""
          disable-pagination
          hide-default-footer
      >
        <template #item.createdAt="{item}">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template #item.acoes="{item}">
          <v-layout>
<!--            <details-modal :data="item">-->
<!--              <template #default="{on, attrs}">-->
<!--                <v-btn icon color="blue lighten-2" class="mr-4" v-on="on" v-bind="attrs">-->
<!--                  <v-icon title="detalhes">mdi-card-text</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--            </details-modal>-->
            <form-modal :data="item">
              <template #default="{on, attrs}">
                <v-btn icon color="primary" class="mr-2" v-on="on" v-bind="attrs">
                  <v-icon title="editar">mdi-pencil</v-icon>
                </v-btn>
              </template>
            </form-modal>
            <delete-modal>
              <template #default="{on, attrs}">
                <v-btn icon class="ml-1" color="error" v-on="on" v-bind="attrs">
                  <v-icon title="excluir">mdi-delete</v-icon>
                </v-btn>
              </template>
            </delete-modal>
          </v-layout>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import DeleteModal from "@/components/modals/delete-modal";
import FormModal from "@/views/database/quiz/form";
// import DetailsModal from "@/views/database/quiz/details";
export default {
  components: {
    FormModal,
    DeleteModal,
    // DetailsModal
  },
  data: () => ({
    title: "Quiz",
    tableSearch: "",
    headerProps: {
      sortByText: "Ordenar por",
    },
    headers: [
      { text: "ID", value: "id" },
      { text: "Título", value: "titulo" },
      // { text: "Data Criação", value: "createdAt" },
      // { text: "Ações", value: 'acoes' },
    ],
  }),
  computed: {
    quiz() {
      return this.$store.state.database.quiz;
    }
  },
  mounted() {
    this.$store.dispatch('database/quiz/getAll',{page: this.$route.query.page ? this.$route.query.page : '1'})
  },
  methods: {
    mergeData(dataA, dataB) {
      return {...dataA, ...dataB}
    }
  }
}
</script>

<style lang="scss">

</style>
