<template>
  <v-dialog v-model="show" max-width="300px" persistent>
    <v-card>
      <v-card-title>Excluir?</v-card-title>
      <v-card-text>
        Deseja realmente excluir?
      </v-card-text>
      <v-card-actions class="pb-5 px-6">
        <v-layout justify-space-between>
          <v-btn color="primary" small class="px-6" @click="onClickYes">Sim</v-btn>
          <v-btn color="error" small class="px-6" @click="onClickNo">Não</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
    <template #activator="{ on, attrs }">
      <slot v-bind="{on, attrs}" />
    </template>
  </v-dialog>
</template>

<script>
export default {
  props: {
    onYes: {
      type: Function,
      default: () => {}
    },
    onNo: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    show: false
  }),
  methods: {
    onClickYes() {
      this.onYes();
      this.show = false;
    },
    onClickNo() {
      this.onNo();
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>
