<template>
  <div class="quiz-form">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title class="mb-4">{{ title }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="form.titulo" label="Título" />
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-layout justify-end>
              <v-btn color="white" class="mr-4" @click="onClickCancel">Cancelar</v-btn>
              <v-btn color="primary" @click="onClickSave">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Salvar
              </v-btn>
            </v-layout>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <template #activator="{on, attrs}">
        <slot v-bind="{on, attrs}" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    onSave: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  data: () => ({
    title: '',
    mode: '',
    show: false,
    form: {
      titulo: ''
    }
  }),
  methods: {
    onClickSave() {
      this.onSave();
      this.show = false;
    },
    onClickCancel() {
      this.onCancel();
      this.show = false;
    }
  },
  mounted() {
    if(this.data) {
      this.form = this.data
      this.title = 'Atualizar Dados'
      this.mode = 'editing'
    } else {
      this.title = 'Cadastrar Dados'
      this.mode = 'including'
    }
  }
}
</script>

<style lang="scss">

</style>
